body {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.overlay {
  color: #666;
  background: #0000000d;
  border-radius: 5px;
  padding: 10px;
  font-family: Roboto, sans-serif;
  position: absolute;
  top: 10px;
  left: 10px;
}

.overlay .name {
  font-size: 32px;
  font-weight: bold;
}

.overlay .caption {
  font-size: 18px;
}

.overlay .social-links {
  margin-top: 5px;
  font-size: 16px;
}

.overlay .social-links a {
  color: #555;
  margin-bottom: 3px;
  text-decoration: none;
  transition: color .3s;
  display: block;
}

.overlay .social-links a:hover {
  color: #bbb;
}

.footer {
  color: #666;
  text-align: center;
  background: #0000001a;
  width: 100%;
  padding: 10px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  position: fixed;
  bottom: 0;
  left: 0;
}

/*# sourceMappingURL=index.c41b987a.css.map */
