body {
  margin: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.overlay {
    position: absolute;
    top: 10px;
    left: 10px;
    background: rgba(0, 0, 0, 0.05);
    color: #666666;
    font-family: 'Roboto', sans-serif;
    padding: 10px;
    border-radius: 5px;
}

.overlay .name {
    font-weight: bold;
    font-size: 32px;
}
.overlay .caption {
    font-size: 18px;
}

.overlay .social-links {
    font-size: 16px;
    margin-top: 5px;
}

.overlay .social-links a {
    display: block;
    margin-bottom: 3px;
    text-decoration: none;
    color: #555555;
    transition: color 0.3s;
}

.overlay .social-links a:hover {
    color: #bbbbbb;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.1);
    color: #666666;
    text-align: center;
    padding: 10px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
}

